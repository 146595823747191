<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="600"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      v-if="dialogVisible"
      label-width="140px"
      class="demo-ruleForm"
    >
      <el-form-item label="代理人名称" prop="agentName">
        <el-input v-model="ruleForm.agentName"></el-input>
      </el-form-item>
      <el-form-item label="代理人编码" prop="agentId">
        <el-input v-model="ruleForm.agentId"></el-input>
      </el-form-item>
      <el-form-item label="代理人联系方式" prop="agentPhone">
        <el-input v-model="ruleForm.agentPhone" maxlength="11"></el-input>
      </el-form-item>
      <el-form-item label="绑定业务员" prop="salesmanId">
        <EmployeeSelect
          v-model="ruleForm.salesmanId"
          placeholder="请选择"
          @change="changeValidateField"
        />
      </el-form-item>
      <el-form-item label="启用" prop="isEnable">
        <el-radio-group v-model="ruleForm.isEnable">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input type="textarea" v-model="ruleForm.remark" rows="5"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
        <el-button @click="resetForm('ruleForm')">重 置</el-button>
      </el-form-item>
    </el-form>
    <!-- <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleClose" size="mini">确 定</el-button>
      <el-button @click="handleClose" size="mini">重 置</el-button>
    </span>-->
  </el-dialog>
</template>

<script>
import { listTenant, agentSaveOrUpdate } from "@/api/SystemData";
import EmployeeSelect from "@/components/EmployeeSelect/index.vue";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    editData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    EmployeeSelect,
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (v && this.editData.id) {
          this.ruleForm = JSON.parse(JSON.stringify(this.editData));
        } else if (!v) {
          this.ruleForm = {
            isEnable: 1,
            agentName: "",
            agentId: "",
            agentPhone: "",
            salesmanId: "",
            salesmanName: "",
            remark: "",
          };
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      ruleForm: {
        isEnable: 1,
        agentName: "",
        agentId: "",
        agentPhone: "",
        salesmanId: "",
        salesmanName: "",
        remark: "",
      },
      rules: {
        agentName: [{ required: true, message: "请输入", trigger: "change" }],
        agentId: [{ required: true, message: "请输入", trigger: "change" }],
        agentPhone: [
          {
            required: true,
            min: 11,
            max: 11,
            message: "请输入十一位手机号",
            trigger: "change",
          },
        ],
        salesmanId: [{ required: true, message: "请选择", trigger: "change" }],
        remark: [
          {
            min: 0,
            max: 128,
            message: "长度不能超过128个字符",
            trigger: "change",
          },
        ],
      },
      loading: true,
      options: [],
    };
  },
  async created() {
    // this.loading = true;
    // let list = await this.initList();
    // this.loading = false;
    if (this.$store.state.tenantId === "000000") {
      this.options = JSON.parse(JSON.stringify(this.$store.state.tenantList));
      this.options.splice(0, 1);
    }
    // else {
    //   this.options = [];
    // }
  },
  methods: {
    initList(query = "") {
      return new Promise((resolve, reject) => {
        listTenant({
          tenantName: query,
        })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(false);
          });
      });
    },
    handleClose() {
      this.$emit("update:dialogVisible", false);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          agentSaveOrUpdate({ ...this.ruleForm }).then((res) => {
            this.handleClose();
            this.$emit("changeList");
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    changeValidateField(e, v) {
      this.ruleForm.salesmanName = v;
      this.$refs["ruleForm"].validateField("salesmanId");
    },
    resetForm(type) {
      if (this.editData.id) {
        this.ruleForm = JSON.parse(JSON.stringify(this.editData));
      } else {
        this.ruleForm = {
          isEnable: 1,
          agentName: "",
          agentId: "",
          agentPhone: "",
          salesmanId: "",
          salesmanName: "",
          remark: "",
        };
      }
    },
    async remoteMethod(query) {
      this.loading = true;
      let list = await this.initList(query);
      this.loading = false;
      if (list) {
        this.options = list;
      } else {
        this.options = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background: #4b7bca;
  .el-dialog__title {
    color: #fff;
  }
}
</style>
